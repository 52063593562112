body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    position: absolute !important;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Texta Alt Regular";
  src: url("./fonts/TextaAlt-Regular.woff") format('opentype');
}

@font-face {
  font-family: "Texta Alt Heavy";
  src: url("./fonts/TextaAlt-Heavy.woff") format('opentype');
}

@font-face {
  font-family: "Texta Alt Medium";
  src: url("./fonts/TextaAlt-Medium.woff") format('opentype');
}


@font-face {
  font-family: "Texta Alt Bold";
  src: url("./fonts/TextaAlt-Bold.woff") format('opentype');
}

@font-face {
  font-family: "ff-tisa-web-pro";
  src: url('./fonts/ff-tisa-web-pro.woff') format('woff');
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}